import { bindActionCreators } from 'redux';

import { getInstance } from '../../common/controller/helpers';
import { initializeActions as initializeCommonActions } from '../../common/controller/init-actions';
import { setAppConfig } from '../../common/store/app-config/app-config-actions';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { initializeInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import { getInstanceId } from '../../common/store/instance-values/instance-values-selectors';
import {
  setLocale,
  setQueryLocaleAction,
} from '../../common/store/locale/locale-actions';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import { fetchArchiveWidgetInitialData } from '../actions/fetch-initial-data';

export {
  initializePromisifiedActions,
  refreshDataOnLogin,
} from '../../common/controller/init-actions';

export function initializeActions({ wixCodeApi, store, fedopsLogger }) {
  return {
    ...initializeCommonActions({ wixCodeApi, store, fedopsLogger }),
    ...bindActionCreators(
      {
        fetchArchiveWidgetInitialData,
      },
      store.dispatch,
    ),
  };
}

export async function initializeStoreBaseData({
  wixCodeApi,
  store,
  language,
  platformAPIs,
  bundleName,
  appParams,
  showNewestPostsFirst,
  monthsDisplayLimit,
}) {
  const viewMode = wixCodeApi.window.viewMode.toLowerCase();

  store.dispatch(initializeInstanceValues(getInstance(wixCodeApi)()));

  const instanceId = getInstanceId(store.getState());
  store.dispatch(setLocale(language));
  store.dispatch(setQueryLocaleAction(wixCodeApi, appParams));

  await Promise.all([
    store.dispatch(
      setBasicParams({
        viewMode,
        language,
        biPageNumber: platformAPIs.bi.pageNumber,
      }),
    ),
    store.dispatch(setAppConfig({ bundleName })),
    store.dispatch(
      fetchArchiveWidgetInitialData({
        showNewestPostsFirst,
        monthsDisplayLimit,
      }),
    ),
  ]);
  await store.dispatch(fetchTopology(instanceId));
}
