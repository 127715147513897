import { formatDate, formatDateICU } from '@wix/communities-blog-client-common';
import { type WixCodeApi } from '../../external/common/types';

export class DateFormatter {
  constructor(private readonly wixCodeApi: WixCodeApi) {}

  format(date: Date) {
    return formatDate({
      date: date.toString(),
      lng: this.wixCodeApi.site.language,
      timezone: this.wixCodeApi.site.timezone,
    });
  }

  formatICU(date: Date) {
    return formatDateICU({
      date: date.toString(),
      lng: this.wixCodeApi.site.language,
      timeZone: this.wixCodeApi.site.timezone,
    });
  }
}
