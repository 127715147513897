import { getStylesValues } from '@wix/tpa-settings';
import type { CreateControllerFn } from '@wix/yoshi-flow-editor';
import stylesParams from '../../../components/Archive/stylesParams';
import {
  createControllerId,
  createLogger,
} from '../../common/controller/helpers';
import { initLazyActions } from '../../common/controller/lazy-actions';
import { getWixCodeApi } from '../../common/controller/wix-code-api';
import { createMonitoring } from '../../common/services/create-monitoring';
import { getCurrentSiteLanguage } from '../../common/services/get-current-site-language';
import getEnvironment from '../../common/services/get-environment';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import listenToInstanceChange from '../../common/services/listen-to-instance-change';
import { simulateControllerError } from '../../common/services/simulate-error';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { getInitialStateVersions } from '../../common/services/state-optimizer/change-detector';
import { createReduxStore } from './create-redux-store';
import {
  initializeActions,
  initializePromisifiedActions,
  initializeStoreBaseData,
  refreshDataOnLogin,
} from './init-actions';

const isProduction = process.env.NODE_ENV === 'production';

export const createArchiveController: CreateControllerFn = async ({
  controllerConfig: {
    appParams,
    compId,
    config,
    setProps,
    platformAPIs,
    platformApiProvider,
  },
  flowAPI,
}) => {
  const wixCodeApi = await getWixCodeApi(platformApiProvider);
  const { isSSR, isDebug } = getEnvironment(wixCodeApi);
  const { fedopsLogger } = createMonitoring(flowAPI);
  const language = getCurrentSiteLanguage(wixCodeApi, flowAPI);
  const log = createLogger(isDebug, isProduction);

  log('createArchiveController', {
    appParams,
    wixCodeApi,
    isSSR,
    language,
  });

  const bundleName = 'archive-widget';

  const store = createReduxStore({
    appParams,
    wixCodeApi,
    compId,
    fedopsLogger,
    bundleName,
    flowAPI,
  });

  const actions = initializeActions({
    wixCodeApi,
    store,
    fedopsLogger,
  });

  const {
    showNewestPostsFirstV2: showNewestPostsFirst,
    monthsDisplayLimitV2: monthsDisplayLimit,
  } = getStylesValues(config.style.styleParams, stylesParams, {
    isMobile: flowAPI.environment.isMobile,
  });

  const params = {
    showNewestPostsFirst,
    monthsDisplayLimit,
  };

  const pageReady = async () => {
    log('createArchiveController.pageReady -> start');

    simulateControllerError(wixCodeApi, 'archive.pageReady'); // window, location

    initLazyActions({ isSSR });

    const actionsPromisified = initializePromisifiedActions({
      store,
    });

    await initializeStoreBaseData({
      wixCodeApi,
      store,
      language,
      platformAPIs,
      bundleName,
      appParams,
      showNewestPostsFirst,
      monthsDisplayLimit,
    });
    listenToInstanceChange(wixCodeApi, appParams, store);

    log('createArchiveController.pageReady -> done');
    const state = store.getState();

    const stateVersions = getInitialStateVersions(state);
    const controllerId = createControllerId();

    setProps({
      state,
      stateVersions,
      actions,
      actionsPromisified,
      isSSR,
      isRTL: isRtlLanguage(language),
      controllerId,
      fitToContentHeight: true,
      publicData: config.publicData,
      stylesParams: config.style.styleParams,
    });

    refreshDataOnLogin({ wixCodeApi, store, router: undefined });
    subscribeToChange(store, stateVersions, setProps, controllerId);
  };

  return {
    pageReady,
    exports: () => {},
    updateConfig(_, configuration) {
      if (flowAPI.environment.isEditor) {
        const { showNewestPostsFirstV2, monthsDisplayLimitV2 } =
          getStylesValues(configuration.style.styleParams, stylesParams, {
            isMobile: flowAPI.environment.isMobile,
          });

        const booleanStyleParamsToTriggerApiHaveChanged =
          showNewestPostsFirstV2 !== params.showNewestPostsFirst ||
          monthsDisplayLimitV2 !== params.monthsDisplayLimit;

        if (booleanStyleParamsToTriggerApiHaveChanged) {
          params.showNewestPostsFirst = showNewestPostsFirstV2;
          params.monthsDisplayLimit = monthsDisplayLimitV2;

          actions.fetchArchiveWidgetInitialData({
            showNewestPostsFirst: Boolean(showNewestPostsFirstV2),
            monthsDisplayLimit: Number(monthsDisplayLimitV2),
          });
        }
      }
    },
  };
};
