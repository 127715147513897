import { type HttpResponse } from '@wix/http-client';
import { getStylesValues } from '@wix/tpa-settings';
import {
  type CreateControllerFn,
  type IWixStyleParams,
} from '@wix/yoshi-flow-editor';
import { queryTags } from '@wix/ambassador-blog-v3-tag/http';
import {
  type PagingMetadataV2,
  SortOrder,
  type Tag,
  type CursorPaging,
  type Paging,
  type PlatformQuery,
} from '@wix/ambassador-blog-v3-tag/types';
import {
  BLOG_APP_ID,
  BLOG_SECTION_ID,
} from '@wix/communities-universal/dist/src/constants/appsConfig';
import {
  FEED_PAGE_URLS,
  WP_BLOGS,
} from '@wix/communities-universal/dist/src/constants/wix-blogs';
import { EXPERIMENTS, TagOrder } from '@wix/communities-blog-client-common';
import { OOI_EXPERIMENTS } from '@app/experiments';
import { fetchDataInChunksForNile } from '@app/external/common/services/fetch-data-in-chunks-for-nile';
import { createTagCloudController } from '../../external/tag-cloud-widget/controller';
import stylesParamsDefinitions from './stylesParams';

const createController: CreateControllerFn = (params) => {
  if (
    !params.flowAPI.experiments.enabled(
      OOI_EXPERIMENTS.USE_NEW_TAG_CLOUD_SETTINGS_URL,
    )
  ) {
    return createTagCloudController(params);
  }

  const {
    controllerConfig: { appParams, config, setProps, wixCodeApi },
    flowAPI,
  } = params;

  const feedBaseUrlPromise = resolveFeedBaseUrl();

  let styleValuesForData = getTagCloudStylesValuesForData(
    config.style.styleParams,
  );

  return {
    pageReady: async () => {
      const [tags, feedBaseUrl] = await Promise.all([
        resolveTags(),
        feedBaseUrlPromise,
      ]);

      setProps({ tags, feedBaseUrl, fitToContentHeight: true });
    },
    exports: () => {},
    updateConfig: (_$w, updatedConfig) => {
      if (!flowAPI.environment.isEditor) {
        return;
      }

      const nextValues = getTagCloudStylesValuesForData(
        updatedConfig.style.styleParams,
      );

      if (
        nextValues.numberOfTags !== styleValuesForData.numberOfTags ||
        nextValues.orderTags !== styleValuesForData.orderTags
      ) {
        styleValuesForData = nextValues;

        resolveTags().then((tags) => {
          setProps({ tags, fitToContentHeight: true });
        });
      }
    },
  };

  function getTagCloudStylesValuesForData(
    widgetConfigStyleParams: IWixStyleParams,
  ) {
    const values = getStylesValues(
      widgetConfigStyleParams,
      stylesParamsDefinitions,
      { isMobile: flowAPI.environment.isMobile },
    );

    return {
      numberOfTags: values.numberOfTagsV2 as unknown as number,
      orderTags: values.orderTagsV2 as unknown as TagOrder,
    };
  }

  async function resolveTags(): Promise<Tag[]> {
    try {
      const tags = await fetchDataInChunksForNile({
        filter: {
          publishedPostCount: { $gt: 0 },
          ...(wixCodeApi.window.multilingual.isEnabled
            ? { language: wixCodeApi.window.multilingual.currentLanguage }
            : {}),
        },
        sort: [
          styleValuesForData.orderTags === TagOrder.MostUsed
            ? { fieldName: 'publishedPostCount', order: SortOrder.DESC }
            : { fieldName: 'label', order: SortOrder.ASC },
        ],
        limit: styleValuesForData.numberOfTags,
        dataFn: (query) =>
          flowAPI.httpClient.request(queryTags({ query })).then((response) => ({
            items: response.data.tags ?? [],
            nextCursor: response.data.pagingMetadata?.cursors?.next,
          })),
      });

      return tags;
    } catch (err) {
      if (err instanceof Error || typeof err === 'string') {
        flowAPI.reportError(err);
      }
      return [];
    }
  }

  async function resolveFeedBaseUrl(): Promise<string> {
    if (WP_BLOGS.includes(appParams.instanceId)) {
      return FEED_PAGE_URLS[appParams.instanceId];
    }

    if (flowAPI.experiments.enabled(EXPERIMENTS.USE_SITE_ROOT_LINKS)) {
      return wixCodeApi.location.baseUrl;
    }

    const resolvedSection = await wixCodeApi.site.getSectionUrl({
      sectionId: BLOG_SECTION_ID,
      appDefinitionId: BLOG_APP_ID,
    });

    return resolvedSection.url ?? '';
  }
};

export default createController;
