// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OOI_EXPERIMENTS } from '@app/experiments';
import { TagCloudView } from '../types';
import { tagCloudWidgetSuccess } from './tag-cloud.actions';

type TagCloudWidgetState = Partial<TagCloudView>;

type AnyAction = ReturnType<typeof tagCloudWidgetSuccess>;

const tagCloudWidgetReducer = (
  state: TagCloudWidgetState = {},
  action: AnyAction,
) => {
  switch (action.type) {
    case tagCloudWidgetSuccess.type:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

/** @deprecated Remove when merging {@link OOI_EXPERIMENTS.USE_NEW_TAG_CLOUD_SETTINGS_URL} */
export default tagCloudWidgetReducer;
