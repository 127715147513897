import {
  type AggregatedResponse,
  createAction,
  handleAggregatorResponse,
} from '@wix/communities-blog-client-common';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OOI_EXPERIMENTS } from '@app/experiments';
import { getQueryLocale } from '../../common/selectors/locale-selectors';
import type { TagCloudThunkAction, TagCloudView } from '../types';

type TagCloudRenderModelResponse = {
  tagCloud: AggregatedResponse<TagCloudView>;
};

/** @deprecated Remove when merging {@link OOI_EXPERIMENTS.USE_NEW_TAG_CLOUD_SETTINGS_URL} */
export const fetchTagCloudWidgetInitialData =
  (): TagCloudThunkAction =>
  async (dispatch, getState, { aggregatorRequest }) => {
    const langQuery = getQueryLocale(getState());
    const language = langQuery ? `&language=${langQuery}` : '';

    const response = await aggregatorRequest<TagCloudRenderModelResponse>(
      `/v1/tag-cloud-widget/render-model?onlyWithPublishedPosts=true${language}`,
      {
        throwOnInvalidJson: true,
      },
    );

    try {
      const tagCloud = await dispatch(
        handleAggregatorResponse(response.tagCloud),
      );
      dispatch(tagCloudWidgetSuccess(tagCloud));
    } catch (error) {
      console.error(error);
      tagCloudWidgetFailure();
    }
  };

/** @deprecated Remove when merging {@link OOI_EXPERIMENTS.USE_NEW_TAG_CLOUD_SETTINGS_URL} */
export const tagCloudWidgetSuccess = createAction(
  'tagCloudWidget/SUCCESS',
  (payload: TagCloudView) => payload,
);
const tagCloudWidgetFailure = createAction('tagCloudWidget/FAILURE', () => {});
